import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlf-ors-hps-main',
  templateUrl: './ors-hps-main.component.html',
  styleUrls: ['./ors-hps-main.component.css']
})
export class NlfOrsHpsMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
