import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiObservationInjuriesInterface, ApiObservationsItem, NlfConfigItem } from 'app/api/api.interface';
import { NlfConfigService } from 'app/nlf-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEdit, faPlus, faCheck, faTimes, faAmbulance } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'nlf-ors-editor-person-injuries',
  templateUrl: './ors-editor-person-injuries.component.html',
  styleUrls: ['./ors-editor-person-injuries.component.css']
})
export class NlfOrsEditorPersonInjuriesComponent implements OnInit {

  @Input() injuries: ApiObservationInjuriesInterface = {};
  @Input() activity: string;
  @Input() disable: boolean = false;
  @Output() injuriesChange: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<boolean> = new EventEmitter();


  public config: NlfConfigItem;
  public modalRef; 

  faAmbulance = faAmbulance;
  faCheck = faCheck;
  faTimes = faTimes;
  faEdit = faEdit;
  faPlus = faPlus;

  selectedParts: string[] = [];
  svgStyle = {};
  isZoomed = false;
  
  constructor(
    private modalService: NgbModal,
    private configService: NlfConfigService
  ) {


    this.configService.observableConfig.subscribe(
      data => {
        this.config = data;
        //this.activity_config = this.config[this.activity]
      }
    );
  }

  ngOnInit(): void {
    this.selectedParts = this.injuries?.frontDiagram || [];
  }

  update(event) {
    // this.subject.update(this.observation);
    this.injuriesChange.emit(this.injuries);
    this.change.emit(true);
  }

  onPartClicked(part: string): void {
    if (this.selectedParts.includes(part)) {
      this.selectedParts = this.selectedParts.filter(p => p !== part);
    } else {
      this.selectedParts = [...this.selectedParts, part];
    }
    console.log(`Clicked ${part}. Selected parts:`, this.selectedParts);
    this.injuries.frontDiagram = this.selectedParts;
    
  }

  toggleZoom(): void {
    this.isZoomed = !this.isZoomed;
  }

  openModal(template) {
    this.modalRef = this.modalService.open(template, { size: 'lg' });
  }

  closeModal() {
    this.update(true);
    this.modalRef.close();
  }
}
