import { DomSanitizer } from '@angular/platform-browser';
import { ApiObservationFileInterface, ApiObservationsItem, ApiOptionsInterface } from 'app/api/api.interface';
import { NlfOrsEditorService } from 'app/ors/ors-editor/ors-editor.service';
import { ApiFilesService } from 'app/api/api-files.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmService } from 'app/services/confirm/confirm.service';
import { NlfUserSubjectService } from 'app/user/user-subject.service';
import { NlfAuthSubjectService } from 'app/services/auth/auth-subject.service';
import { faPlus, faCamera, faDownload, faTimes, faLock, faUnlock, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; import { } from '@fortawesome/free-solid-svg-icons';
import { Component, ElementRef, HostListener, ViewChild, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';


interface UploadFile {
  file: File;
  progress: number;
  status: string;
  subscription: Subscription | null;
  metadata: {
    name: string;
    size: number;
    type: string;
    lastModified: number;
    ref_id?: string;
    ref?: string;
    content_type?: string;
    owner?: string | number;
  };
  errorMessage?: string; // Added for error messages
  thumbnail?: string; // Added for image thumbnails
}

interface UploadSuccessResponse {
  _id: string;
  _etag: string;
  _updated: string;
  _created: string;
  _links: {
    self: {
      title: string;
      href: string;
    };
  };
  _status: string;
}

@Component({
  selector: 'nlf-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class NlfFileUploaderComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;

  @Input() reference_collection: string = '';
  @Input() reference_collection_id: string = '';
  @Input() owner: number | string;
  @Input() api_url: string = '/api/v1/files';
  @Input() showUploadsList: boolean = true;
  @Input() isDragging: boolean = false;
  @Output() fileUploaded = new EventEmitter<{ _id: string, action: string }>();

  faCamera = faCamera;
  
  files: UploadFile[] = [];
  isMobile: boolean = window.innerWidth <= 768;
  private autoCloseTimeout: any;
  constructor(private http: HttpClient) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = window.innerWidth <= 768;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isMobile) {
      this.isDragging = true;
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isMobile) {
      this.isDragging = false;
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isMobile && event.dataTransfer?.files) {
      this.handleFiles(event.dataTransfer.files);
      this.isDragging = false;
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.handleFiles(input.files);
    }
  }

  handleFiles(files: FileList): void {
    Array.from(files).forEach(file => {
      const uploadFile: UploadFile = {
        file,
        progress: 0,
        status: 'Pending',
        subscription: null,
        metadata: {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          ref: this.reference_collection,
          ref_id: this.reference_collection_id,
          content_type: file.type,
          owner: this.owner,
        },

      };

      // Generate thumbnail if it's an image
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          uploadFile.thumbnail = e.target.result;
        };
        reader.readAsDataURL(file);
      }

      this.files.push(uploadFile);
    });
    this.showUploadsList = true;
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  startUploads(): void {
    this.files
      .filter(file => file.status === 'Pending')
      .forEach((file, index) => {
        this.uploadFile(this.files.indexOf(file));
      });
  }

  uploadFile(index: number): void {
    if (index < 0 || index >= this.files.length) return; // Safety check

    this.files[index].status = 'Uploading...';
    this.files[index].progress = 0; // Reset progress explicitly
    this.files[index].errorMessage = undefined;

    const formData = new FormData();
    formData.append('ref', this.reference_collection);
    formData.append('ref_id', this.reference_collection_id);
    formData.append('size', this.files[index].metadata.size.toString());
    formData.append('content_type', this.files[index].metadata.type);
    formData.append('owner', this.files[index].metadata.owner.toString());
    formData.append('name', this.files[index].metadata.name);
    formData.append('last_modified', new Date(this.files[index].metadata.lastModified).toISOString());
    formData.append('file', this.files[index].file, this.files[index].metadata.name);

    /**
     * description
     * tags
     * activity
     * acl[{}]
     */

    //const req = new HttpRequest('POST', this.api_url, formData, {
    //  reportProgress: true
    //});
    const req$ = this.http.post(this.api_url, formData, {
      reportProgress: true,
      observe: 'events'
    });

    this.files[index].subscription = req$.subscribe(
      (event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.files[index].status = 'Upload started'; // Direct array access
            break;
          case HttpEventType.UploadProgress:
            if (event.total) {
              console.log(index, event.total);
              this.files[index].progress = Math.round((event.loaded / event.total) * 100);
              this.files[index].status = `Uploading... ${this.files[index].progress}%`;
            }
            break;
          case HttpEventType.Response:
            this.files[index].status = 'Upload completed successfully';
            this.files[index].subscription = null;
            const response = event.body as UploadSuccessResponse;
            if (response && response._id) {
              this.fileUploaded.emit({
                _id: response._id,
                action: 'uploaded'
              });
            }
            this.checkAllUploadsComplete();
            break;
        }
      },
      (error: HttpErrorResponse) => {
        this.files[index].status = 'Upload failed';
        this.files[index].progress = 0;
        this.files[index].subscription = null;

        if (error.error && error.error._error && error.error._error.message) {
          this.files[index].errorMessage = `${error.error._error.code}: ${error.error._error.message}`;
          if (error.error._issues) {
            const issues = Object.entries(error.error._issues)
              .map(([field, msg]) => `${field}: ${msg}`)
              .join(', ');
            this.files[index].errorMessage += ` (${issues})`;
          }
        } else {
          this.files[index].errorMessage = 'Unknown error occurred';
        }
        this.checkAllUploadsComplete();
      }
    );
  }

  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  cancelUpload(index: number): void {
    if (index < 0 || index >= this.files.length) return; // Safety check

    const uploadFile = this.files[index];
    if (uploadFile.subscription) {
      uploadFile.subscription.unsubscribe();
      uploadFile.status = 'Upload cancelled';
      uploadFile.progress = 0;
      uploadFile.subscription = null;
      uploadFile.errorMessage = undefined;
      this.checkAllUploadsComplete();
    }
  }

  cancelAllUploads(): void {
    this.files.forEach((_, index) => this.cancelUpload(index));
  }

  removeFile(index: number): void {
    if (index < 0 || index >= this.files.length) return; // Safety check

    this.cancelUpload(index);
    this.files.splice(index, 1);
    if (this.files.length === 0) {
      this.showUploadsList = false;
    }
  }

  resetFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  isUploading(): boolean {
    return this.files.some(file => file.subscription !== null);
  }

  hasPendingUploads(): boolean {
    return this.files.some(file => file.status === 'Pending');
  }

  clearCompleted(): void {
    this.files = this.files.filter(file => file.subscription !== null);
    if (this.files.length === 0) {
      this.showUploadsList = false;
    }
  }

  private checkAllUploadsComplete(): void {
    if (!this.isUploading()) {
      clearTimeout(this.autoCloseTimeout);
      this.autoCloseTimeout = setTimeout(() => {
        this.showUploadsList = false;
        this.files = [];
      }, 5000);
    }
  }
}
