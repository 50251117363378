import { Component, OnInit, Input } from '@angular/core';
import { ApiObservationsHpsItem, ApiObervationHpsInvolvedInterface, ApiObservationHpsInvolvedGearInterface, ApiOptionsInterface, ApiObservationHpsOrganizationInterface } from 'app/api/api.interface';
import { NlfOrsEditorInvolvedService, NlfOrsEditorInvolvedInterface } from 'app/ors/ors-editor/ors-editor-involved.service';
import { NlfOrsEditorService } from 'app/ors/ors-editor/ors-editor.service';
import { NgbModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { faCalendar, faBan, faCheck, faExclamation, faTimes, faEdit, faPlus, faAmbulance } from '@fortawesome/free-solid-svg-icons';
import { createEmptyObjectFromInterface } from 'app/interfaces/functions';
@Component({
  selector: 'nlf-ors-hps-editor-involved',
  templateUrl: './ors-hps-editor-involved.component.html',
  styleUrls: ['./ors-hps-editor-involved.component.css']
})
export class NlfOrsHpsEditorInvolvedComponent implements OnInit {

  observation: ApiObservationsHpsItem;
  involved;
  modalIndex: number;
  modalPerson;
  modalRef;
  today;

  public active_tab = 1;

  deleteExternal = false;


  faCheck = faCheck;
  faExclamation = faExclamation;
  faBan = faBan;
  faTimes = faTimes;
  faEdit = faEdit;
  faCalendar = faCalendar;
  faPlus = faPlus;
  faAmbulance = faAmbulance;

  constructor(
    private subject: NlfOrsEditorService,
    private involvedService: NlfOrsEditorInvolvedService,
    private modalService: NgbModal,
    private calendar: NgbCalendar) {

    this.today = this.calendar.getToday();

    this.subject.observableObservation.subscribe(
      (observation: ApiObservationsHpsItem) => {
        this.observation = observation;
        if (!this.involved) {
          this.involved = [...this.observation.involved];
          this.involved.forEach(person => {
            this.involvedService.add(person.id, person.full_name || person.tmp_name || undefined);
          });
        }
      });
  }


  ngOnInit() {
  }




  onChange(event) {
    /**
    Check if adding or removing - kkeping involved subject updated
    map array of objects to array of id's not in array of ids for the shorter array of objects
    **/
    if (this.observation.involved.length > this.involved.length) { // Remove
      this.involvedService.remove(this.observation.involved.map(item => item.id).filter(x => this.involved.map(i => i.id).indexOf(x) < 0)[0]);
    } else if (this.observation.involved.length < this.involved.length) { // Add

      this.involved.forEach((person, idx) => {
        if (!person.hasOwnProperty('data')) {
          this.involved[idx]['data'] = {};
        }
        if (!person['data'].hasOwnProperty('injuries')) {
          this.involved[idx]['data']['injuries'] = {};
        }
        if (!person['data'].hasOwnProperty('gear')) {
          this.involved[idx]['data']['gear'] = {wing: {}, harness: {}, power_unit: {}, emergency_parachute: {}, other: {} }; //<ApiObservationHpsInvolvedGearInterface>{wing: ''};
        }
      });

      this.involvedService.add(
        this.involved.map(item => item.id).filter(x => this.observation.involved.map(i => i.id).indexOf(x) < 0)[0],
        this.involved.map(item => item.tmp_name || undefined).filter(x => this.observation.involved.map(i => i.tmp_name || undefined).indexOf(x) < 0)[0]
      );
    }
    //.map(({ id }) => id)

    this.observation.involved = [...this.involved];
    this.observation.involved = this.observation.involved.filter((props) => {
      delete props.full_name;
      return true;
    });
    this.subject.update(this.observation);
  }

  updateInjuries(event, index) {
    console.log('Injuries', event, this.involved[index].data.injuries);
  }
  /**
  External Remove
  **/
  onRemove(index) {
    console.log('Removal index', index, this.observation.involved[index]);
    console.log(this.observation.involved.length);
    this.deleteExternal = true; // set external flag
    this.involvedService.remove(this.involved[index]['id']);
    this.involved.splice(index, 1); // delete
    this.involved = [...this.involved]; // trigger change detection
    this.onChange(true);
  }

  public openPersonModal(template, index) {
    this.modalIndex = index;
    this.modalPerson = <ApiObervationHpsInvolvedInterface>this.involved[index];

    this.modalRef = this.modalService.open(template, { size: 'lg' });
  }

  modalInvolvedUpdate(index) {
    this.involved[index] = this.modalPerson;
    this.onChange(true);
    this.modalRef.close();
  }

}
