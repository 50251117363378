import { Component, OnInit, Output, Input, TemplateRef, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiObservationFileInterface, ApiObservationsItem, ApiOptionsInterface, ApiFileItem } from 'app/api/api.interface';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from '@angular-ex/uploader';
import { NlfOrsEditorService } from 'app/ors/ors-editor/ors-editor.service';
import { ApiFilesService } from 'app/api/api-files.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmService } from 'app/services/confirm/confirm.service';
import { NlfUserSubjectService } from 'app/user/user-subject.service';
import { NlfAuthSubjectService } from 'app/services/auth/auth-subject.service';
import { faPlus, faCamera, faDownload, faTimes, faLock, faUnlock, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; import { } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
@Component({
  selector: 'nlf-ors-editor-files',
  templateUrl: './ors-editor-files.component.html',
  styleUrls: ['./ors-editor-files.component.css']
})
export class NlfOrsEditorFilesComponent implements OnInit {

  faPlus = faPlus;
  faCamera = faCamera;
  faDownload = faDownload;
  faTimes = faTimes;
  faLock = faLock;
  faUnlock = faUnlock;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  @Input() onlyFilepicker: boolean;
  @Input() dropzone: boolean;
  // Notify editor component if we change files - save
  @Output() fileChange = new EventEmitter<boolean>();

  observation: ApiObservationsItem;
  dataReady = false;

  filelist: ApiFileItem[];

  // Images
  thumbnailSize = 'small';
  modalRef;
  viewimage;
  viewImageLoading = false;
  viewImageName = '';

  humanizeBytes: Function;

  userData;
  token: string;

  constructor(
    private subject: NlfOrsEditorService,
    private apiFile: ApiFilesService,
    public domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private confirmService: ConfirmService,
    private userSubject: NlfUserSubjectService,
    private authDataSubject: NlfAuthSubjectService) {


    // this.options = { concurrency: 3, maxUploads: 50, maxFileSize: 3000000000 };
    this.subject.observableObservation.subscribe(
      observation => {
        this.observation = observation;
        this.getFiles();
      });

    this.humanizeBytes = humanizeBytes;

    this.authDataSubject.observableAuthData.subscribe(
      data => {
        if (!!data) {
          this.token = data.token;
        }
      },
      err => console.log('Problem getting token: ', err)
    );
  }

  ngOnInit() { }

  compareFiles(original, newArray) {
    // Extract _ids from both arrays
    // Create maps for quick lookup by _id
    const originalMap = new Map<string, { _id: string, [key: string]: any }>(original.map(item => [item._id, item]));
    const newMap = new Map<string, { _id: string, [key: string]: any }>(newArray.map(item => [item._id, item]));

    // Extract _ids as Sets for comparison
    const originalIds = new Set(originalMap.keys());
    const newIds = new Set(newMap.keys());

    // Find added objects (in newArray but not in original)
    const addedObjects = [...newIds]
      .filter(id => !originalIds.has(id))
      .map(id => newMap.get(id)!); // Non-null assertion since we know it exists in newMap

    // Find removed objects (in original but not in newArray)
    const removedObjects = [...originalIds]
      .filter(id => !newIds.has(id))
      .map(id => originalMap.get(id)!); // Non-null assertion since we know it exists in originalMap


    return {
      added: addedObjects,
      removed: removedObjects
    };

  }
  /**
   * Getting files
   */

  public getFiles() {
    try {
      let processed = 0;
      this.dataReady = true;

      // @TODO should rather take just the changes, not the whole list
      if (!!this.filelist) {
        const diff = this.compareFiles(this.filelist, this.observation.files);
        console.log('Diff er', diff);
        if (this.filelist.length < this.observation.files.length) {
          diff.added.forEach(obj => {
            try {
              this.getFile(obj);
            } catch (e) { console.log(e) }
          });
        }
        else if (this.filelist.length > this.observation.files.length) {
          diff.removed.forEach(obj => {
            this.filelist = this.filelist.filter(item => item._id != obj._id);
          });
        }
      }
      else {
        this.filelist = [];
        this.observation.files.forEach(obj => {
          this.getFile(obj);
          processed++;
          if (this.observation.files.length === processed) {
            this.dataReady = true;
          }
        });
      }
    } catch (e) { /**console.error(e)**/ }
  }

  private getFile(f) {

    if (f.hasOwnProperty('f')) {
      // Do not download files
      const options: ApiOptionsInterface = {
        query: { projection: { file: 0 } }
      };

      this.apiFile.getFile(f.f, options).subscribe(
        data => {
          console.log('Getting file ', f.f);
          data['r'] = f.r;

          if (data.content_type.match(/image/g) != null) {
            // data['image'] = true;
            // this.getImage(data);

            this.apiFile.getImage(data._id, this.thumbnailSize).subscribe(
              image => {
                data['src'] = 'data:' + image.mimetype + ';charset=utf8;base64,' + image.src;
                data['isImage'] = true;
                data['download'] = this.apiFile.getDirectLink(data._id) + '?token=' + this.token;
                this.filelist.push(data);
              },
              err => {
                console.log('Error getting image ' + data._id + ' ' + data.name);
                console.log(err);
              },
            );

          } else {
            data['isImage'] = false;
            if (data.content_type.match(/video/g) != null) {
              data['isVideo'] = true;
            }
            data['download'] = this.apiFile.getDirectLink(data._id) + '?token=' + this.token;
            this.filelist.push(data);
          }
        },
        err => console.log(err)

      );
    }
  }

  public setRestricted(r, _id, index) {

    this.filelist[index]['r'] = r;

    this.observation.files.forEach((item, i) => {
      if (item['f'] === _id) {
        this.observation.files[i]['r'] = r;
        this.subject.update(this.observation);
      }
    });
  }

  public deleteFile(_id, index) {

    this.confirmService.confirm(
      {
        title: 'Bekreft sletting',
        message: 'Er du sikker du vil slette filen <strong>' + this.filelist[index].name + '</strong> ?',
        yes: 'Slett',
        no: 'Avbryt'
      }).then(
        () => {
          this.observation.files.forEach((item, i) => {
            if (this.observation.files[i]['f'] === _id) {
              this.observation.files.splice(i, 1);
            }
          });

          this.apiFile.deleteFile(_id, this.filelist[index]._etag).subscribe(
            data => {
              console.log('Deleted file!', data);
            },
            err => { },
            () => {
              this.filelist.splice(index, 1);
              this.subject.update(this.observation);
              this.fileChange.emit(true);
            }
          )
        },
        () => { }
      );

  }

  public download(file) {
    this.apiFile.downloadFile(file._id);
  }

  /**
   * Todo see if only filelist and index also for src?
   * @param template
   * @param index
   */
  openModal(template: TemplateRef<any>, index) {

    this.viewImageLoading = true;
    this.viewImageName = 'Laster bilde...';

    this.modalRef = this.modalService.open(template, { size: 'lg' });

    this.getModalImage(index);
  }

  public getModalImage(index) {

    if (this.filelist[index]['content_type'].match(/image/g) != null) {

      this.viewImageName = 'Laster fil...';
      this.viewImageLoading = true;
      this.apiFile.getImage(this.filelist[index]['_id'], 'large').subscribe(
        data => {
          this.viewimage = {
            src: 'data:' + data.mimetype + ';charset=utf8;base64,' + data.src,
            index: index
          };
          this.viewImageName = this.filelist[index].name;
          this.viewImageLoading = false;
        },
        err => console.log(err),
        () => this.viewImageLoading = false

      );
    }
    else {
      this.viewImageName = this.filelist[index].name;
      this.viewImageLoading = false;

    }
  }

  openVideoModal(template: TemplateRef<any>, index) {
    this.viewimage = {
      src: this.apiFile.getDirectLink(this.filelist[index]._id),
      index: index,
      url: this.filelist[index].download,
      name: this.filelist[index].name
    }
    this.modalRef = this.modalService.open(template, { size: 'lg' });


  }

}
