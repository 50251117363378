import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css']
})
export class NumberInputComponent implements OnDestroy, OnInit {
  @Input() min: number;
  @Input() max: number;
  @Input() step: number = 1;
  @Input() disabled: boolean = false;
  @Input() default: number;
  @Input() value: number;
  @Input() unit: string = ''; // New input for unit

  @Output() valueChange = new EventEmitter<number>();

  private intervalId: any = null;
  private readonly holdDelay = 200;

  constructor() {
    

  }

  ngOnInit(): void {
    if (!this.value) {
      this.value = this.default || this.min || undefined;
    }
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let newValue = parseInt(input.value) || this.min;

    newValue = this.clampValue(newValue);
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  startIncrement(event: MouseEvent): void {
    console.log('Start increment', this.value);
    this.increment();
    console.log('After increment', this.value);
    this.clearIntervalIfExists();
    this.intervalId = setInterval(() => this.increment(), this.holdDelay);
    event.preventDefault();
  }

  startDecrement(event: MouseEvent): void {
    this.decrement();
    this.clearIntervalIfExists();
    this.intervalId = setInterval(() => this.decrement(), this.holdDelay);
    event.preventDefault();
  }

  stopAction(): void {
    this.clearIntervalIfExists();
  }

  private increment(): void {
    if(!this.value) {
      this.value = 0;
    }
    let newValue = Number(this.value) + Number(this.step);
    newValue = this.clampValue(newValue);
    if (newValue !== this.value) {
      this.value = newValue;
      this.valueChange.emit(this.value);
    }
  }

  private decrement(): void {
    if(!this.value) {
      this.value = 0;
    }
    let newValue = Number(this.value) - Number(this.step);
    newValue = this.clampValue(newValue);
    if (newValue !== this.value) {
      this.value = newValue;
      this.valueChange.emit(this.value);
    }
  }

  private clampValue(val: number): number {
    if (this.max !== undefined && val > this.max) return this.max;
    if (val < this.min) return this.min;
    return val;
  }

  private clearIntervalIfExists(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  ngOnDestroy(): void {
    this.clearIntervalIfExists();
  }
}