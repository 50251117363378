<div class="cropper-container" [ngClass]="{ roundCropper: avatar.round }" [ngStyle]="{ 'width.px': avatar.width, 'height.px': avatar.height }">
  <img class="image-cropper" #cropper />
</div>
<div class="actions">
  <svg
    (click)="onCancel()"
    class="cancel-file"
    width="30"
    height="30"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17218 14.8284L12.0006 12M14.829 9.17157L12.0006 12M12.0006 12L9.17218 9.17157M12.0006 12L14.829 14.8284"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="circle-x"
    />
    <path
      class="circle"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    class="success-file"
    (click)="getImageBlob()"
    width="30"
    height="30"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 12.5L10 15.5L17 8.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" class="circle-x" />
    <path
      class="circle"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>
