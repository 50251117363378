import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'nlf-ors-editor-person-injuries-diagram',
  templateUrl: './ors-editor-person-injuries-diagram.component.html',
  styleUrls: ['./ors-editor-person-injuries-diagram.component.css']
})
export class NlfOrsEditorPersonInjuriesDiagramComponent {

  @Input() selected: string[] = [];
  @Input() svgStyle: any = {};
  @Output() partClicked = new EventEmitter<string>();

  constructor() {}
  
  onPartClick(part: string): void {
    this.partClicked.emit(part);
  }

  isSelected(part: string): boolean {
    return this.selected.includes(part);
  }
}
